var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"explorerTagFilterContainerClass mt-7",staticStyle:{"max-width":"970px"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mr-2"},[_c('explorerStatusFilter',{attrs:{"currentTable":_vm.currentTable,"result":_vm.result}})],1),_c('v-col',{staticClass:"mr-2",attrs:{"md":"4"}},[_c('associatedOption',{attrs:{"fieldAttributes":{
        name: 'Tags Filter',
        associatedOption: _vm.result.domainTestGroups,
      },"type":"tagCombo","value":_vm.groupFilterExplorer,"fieldAttrInput":{
        multiple: true,
        chips: true,
        class: 'tagContainerAdjust',

        id: 'explorerTagFilter',
      },"disabled":_vm.localDisabled},on:{"input":function($event){return _vm.updateFilter(...arguments)}},scopedSlots:_vm._u([{key:"selection",fn:function(slotProps){return [_c('v-chip',{attrs:{"label":"","x-small":"","close":""},on:{"click":function($event){$event.stopPropagation();return _vm.updateOperation(slotProps.item, slotProps.index)},"click:close":function($event){return _vm.removeValue(slotProps.item)}}},[_c('span',{class:_vm.getColor(slotProps.item)},[_vm._v(_vm._s(slotProps.item.text))])])]}}])})],1),_c('v-col',{staticClass:"mr-2",attrs:{"md":"3"}},[_c('searchInput',{key:_vm.currentTable.id,ref:"explorerSearch",attrs:{"id":"explorerSearchInput"},model:{value:(_vm.localSearch),callback:function ($$v) {_vm.localSearch=$$v},expression:"localSearch"}})],1),_c('v-col',{staticClass:"explorerClearButt",staticStyle:{"max-width":"170px"},attrs:{"cols":"1"}},[_c('primaryButton',{attrs:{"label":"Clear Filter","icon":"mdi-filter-remove","type":"button","color":"primary","id":"explorerFilterClear","shrink":"lgAndDown"},on:{"click":function($event){$event.preventDefault();return _vm.applyFilter('showAll')}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }